var React = require("react");
var actionCreator = require("../actions/userActionCreator");
var Input = require("react-bootstrap").Input;
var Panel = require("react-bootstrap").Panel;
var Alert = require("react-bootstrap").Alert;
var store = require("../stores/userStore");

var Login = React.createClass({

	contextTypes: {
    router: React.PropTypes.object.isRequired
  },

	getInitialState: function() {
		return store.getUserState();
	},

  changeHandler: function() {
  	this.setState(store.getUserState());
  },

  componentDidMount: function() {
    store.addChangeListener(this.changeHandler);
		this.checkLoggedIn(this.state);
  },

  componentWillUnmount: function() {
    store.removeChangeListener(this.changeHandler);
  },

	componentWillUpdate: function(newProps, newState) {
		this.checkLoggedIn(newState);
	},

	checkLoggedIn: function(s) {
    if (s.loginCompleted) {
			var redirectPath = window.location.pathname.replace("/login", "");
			if (redirectPath === "") {
				redirectPath = "/";
			}
      this.context.router.replace(redirectPath);
    }
  },

	handleLoginSubmit: function(e) {
		e.preventDefault();
		var email = this.refs.email.getValue();
		var password = this.refs.password.getValue();
		actionCreator.login(email, password);
	},

	onGetPassword: function(e) {
		e.preventDefault();
		this.context.router.replace(window.location.pathname.replace("/login", "/get-password"));
	},

  render: function() {
		console.log("render: ", this.state);
		var error = "";
		if (this.state.loginError) {
			error = (
				<Alert bsStyle="danger">You did your best, but that was just plain wrong</Alert>
			);
		}

    return (
  		<div className="login-form-wrapper">
				<Panel header="Log in">
					<form name="loginForm" action="#" onSubmit={this.handleLoginSubmit}>
						<div>
							<Input
								type="email"
								placeholder="E-mail"
								label="E-mail:"
								ref="email" />
						</div>
						<div>
							<Input
								type="password"
								placeholder="Passord"
								label="Password:"
								ref="password" />
						</div>
						{error}
						<div><button type="submit" className="btn btn-primary">Logg inn</button></div>
					</form>
					<div className="get-password">
						<p>Don't have a password, or can't remember?</p>
						<p><a href="#" onClick={this.onGetPassword}>Get a new password here!</a></p>
					</div>
				</Panel>
			</div>
    );
  }
});

module.exports = Login;
