var React = require("react");
var actionCreator = require("../actions/userActionCreator");
var Input = require("react-bootstrap").Input;
var Panel = require("react-bootstrap").Panel;
var Alert = require("react-bootstrap").Alert;
var store = require("../stores/userStore");

var GetPassword = React.createClass({

	contextTypes: {
    router: React.PropTypes.object.isRequired
  },

	getInitialState: function() {
		return store.getUserState();
	},

  changeHandler: function() {
  	this.setState(store.getUserState());
  },

  componentDidMount: function() {
    store.addChangeListener(this.changeHandler);
  },

  componentWillUnmount: function() {
    store.removeChangeListener(this.changeHandler);
  },

	handleLoginSubmit: function(e) {
		e.preventDefault();

		var email = this.refs.email.getValue();
		console.log("register: " + email);

		actionCreator.register(email);
	},

	onGotoLogin: function(e) {
		e.preventDefault();
		this.context.router.replace(window.location.pathname.replace("/get-password", "/login"));
	},

  render: function() {
		var message = "";
		var btn = <button type="submit" className="btn btn-primary">Get password</button>;

		if (this.state.registrationComplete) {
			message = <Alert bsStyle="success">A new password was sent to {this.refs.email.getValue()}</Alert>
			btn = <button type="button" className="btn btn-primary" onClick={this.onGotoLogin}>Back to login</button>;
		} else if (this.state.registrationError !== "NONE") {
			message = <Alert bsStyle="danger">E-mail not found. Check with reception.</Alert>
		}

    return (
  		<div className="login-form-wrapper">
				<Panel header="Get a new password">
					<form name="loginForm" action="#" onSubmit={this.handleLoginSubmit}>
						<div>
							<Input
								type="email"
								placeholder="E-mail"
								label="E-mail:"
								ref="email" />
						</div>
						{message}
						<div>{btn}</div>
					</form>
				</Panel>
			</div>
    );
  }
});

module.exports = GetPassword;
