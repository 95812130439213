var React = require("react");
var actionCreator = require("../actions/userActionCreator");
var Input = require("react-bootstrap").Input;
var Panel = require("react-bootstrap").Panel;
var Alert = require("react-bootstrap").Alert;
var store = require("../stores/userStore");

var ChangePassword = React.createClass({

	contextTypes: {
    router: React.PropTypes.object.isRequired
  },

	getInitialState: function() {
		return store.getUserState();
	},

  changeHandler: function() {
  	this.setState(store.getUserState());
  },

  componentDidMount: function() {
    store.addChangeListener(this.changeHandler);
		this.checkLoggedIn(this.state);
  },

  componentWillUnmount: function() {
    store.removeChangeListener(this.changeHandler);
  },

	componentWillUpdate: function(newProps, newState) {
		this.checkLoggedIn(newState);
	},

	handleFormSubmit: function(e) {
		e.preventDefault();
		this.setState({
			passwordMismatch: false
		});

		var oldPassword = this.refs.oldPassword.getValue();
		var newPassword = this.refs.newPassword.getValue();
		var newPasswordVerified = this.refs.newPasswordVerified.getValue();

		if (newPassword !== newPasswordVerified) {
			this.setState({
				passwordMismatch: true
			});
		} else {
			actionCreator.changePassword(oldPassword, newPassword);
		}
	},

	checkLoggedIn: function(s) {
    if (!s.loggedIn) {
      this.context.router.replace(window.location.pathname + "/login");
    }
  },

  render: function() {
		var message = "";
		if (this.state.passwordMismatch) {
			message = <Alert bsStyle="danger">Password and repeated password are not identical.</Alert>
		} else if (this.state.passwordChangeSuccess) {
			message = <Alert bsStyle="success">Your password was changed</Alert>
		} else if (this.state.passwordChangeError) {
			message = <Alert bsStyle="danger">Password was not changed. Check that old password is correct and try again.</Alert>
		}

    return (
  		<div className="login-form-wrapper">
				<Panel header="Change password">
					<form name="passwordForm" action="#" onSubmit={this.handleFormSubmit}>
						<div>
							<Input
								type="password"
								placeholder="Old password"
								label="Old password:"
								ref="oldPassword" />

							<Input
								type="password"
								placeholder="New password"
								label="New password:"
								ref="newPassword" />

							<Input
								type="password"
								placeholder="Repeat password"
								label="Repeat password:"
								ref="newPasswordVerified" />

						</div>
						{message}
						<div><button type="submit" className="btn btn-primary">Change password</button></div>
					</form>
				</Panel>
			</div>
    );
  }
});

module.exports = ChangePassword;
