var React = require("react");
var Route = require("react-router").Route;
var IndexRedirect = require("react-router").IndexRedirect;
var App = require("../components/app");
var Toplist = require("../components/toplist");
var JumpLog = require("../components/jumpLog");
var payments = require("../components/payments");
var login = require("../components/login");
var getPassword = require("../components/get_password");
var changePassword = require("../components/change_password");

var routes = (
  <Route component={App} path="/">
	<IndexRedirect to="toplist" />
    <Route path="toplist" component={Toplist} />
    <Route path="log" component={JumpLog} />
    <Route path="payments" component={payments} />
    <Route path="login" path="/login" component={login} />
    <Route path="login_all" path="*/login" component={login} />
    <Route path="password" path="/get-password" component={getPassword} />
    <Route path="password_all" path="*/get-password" component={getPassword} />
    <Route path="change_password" path="/change-password" component={changePassword} />
  </Route>
);

module.exports = routes;
