var request = require("superagent");
var Promise = require("es6-promise").Promise;

function loadAuthToken() {
  return (sessionStorage && sessionStorage.getItem("jumprunAuthToken")) || null;
}

var jumprunApiClient = {

  authorizationToken: loadAuthToken(),

  isLoggedIn: function() {
    console.log("token: ", this.authorizationToken);
    return this.authorizationToken !== null;
  },

  toplist: function(year) {

    return new Promise(function(resolve, reject) {

      request
        .get("/jrapi/index.php?cmd=toplist&year=" + year)
        .set("Accept", "application/json")
        .end(function(error, res) {
          if (res.status === 200) {
            resolve(JSON.parse(res.text));
          } else {
            reject(error, res.status, res.text);
          }
        });
    });
  },

  log: function(year) {
    var authToken = this.authorizationToken;
    return new Promise(function(resolve, reject) {
      if (authToken === null) {
        reject({status: 401, message: "Not Authorized"}, 401, "Not Authorized");
      } else {
        request
          .get("/jrapi/index.php?cmd=log&year=" + year)
          .set("Accept", "application/json")
          .set("Authorization", authToken)
          .end(function(error, res) {
            if (res.status === 200) {
              resolve(JSON.parse(res.text));
            } else {
              console.log(res.status);
              console.log(error);
              reject(error, res.status, res.text);
            }
          });
      }
    });
  },

  payments: function(year) {
    var authToken = this.authorizationToken;
    return new Promise(function(resolve, reject) {
      if (authToken === null) {
        reject({status: 401, message: "Not Authorized"}, 401, "Not Authorized");
      } else {
        request
          .get("/jrapi/index.php?cmd=payments&year=" + year)
          .set("Accept", "application/json")
          .set("Authorization", authToken)
          .end(function(error, res) {
            if (res.status === 200) {
              resolve(JSON.parse(res.text));
            } else {
              reject(error, res.status, res.text);
            }
          });
      }
    });
  },

  login: function(email, password) {
    var self = this;
    return new Promise(function(resolve, reject) {
      request
        .post("/jrapi/index.php?cmd=login")
        //.set("Accept", "application/json")
        .set("Content-Type", "application/json")
        .send({
          email: email,
          password: password
        })
        .end(function(error, res) {
          if (res.status === 200) {
            console.log(res.header);
            self.authorizationToken = res.header.authorization;
            sessionStorage && sessionStorage.setItem("jumprunAuthToken", self.authorizationToken);
            console.log("auth: " + self.authorizationToken);
            resolve(JSON.parse(res.text));
          } else {
            reject(error, error.status, res.text);
          }
        });
    });
  },

  logout: function() {
    this.authorizationToken = null;
    sessionStorage && sessionStorage.removeItem("jumprunAuthToken");
  },

  register: function(email) {
    var self = this;
    return new Promise(function(resolve, reject) {
      request
        .post("/jrapi/index.php?cmd=register")
        //.set("Accept", "application/json")
        .set("Content-Type", "application/json")
        .send({
          email: email
        })
        .end(function(error, res) {
          if (res.status === 200) {
            resolve(); //JSON.parse(res.text));
          } else {
            reject(error, error.status, res.text);
          }
        });
    });
  },

  changePassword: function(oldPassword, newPassword) {
    var authToken = this.authorizationToken;
    return new Promise(function(resolve, reject) {
      request
        .post("/jrapi/index.php?cmd=changePassword")
        .set("Content-Type", "application/json")
        .set("Authorization", authToken)
        .send({
          oldPassword: oldPassword,
          newPassword: newPassword
        })
        .end(function(error, res) {
          if (res.status === 200) {
            resolve();
          } else {
            reject(error, error.status, res.text);
          }
        });
    });
  },

  currentUser: function() {
    var authToken = this.authorizationToken;
    var self = this;
    return new Promise(function(resolve, reject) {
      request
        .post("/jrapi/index.php?cmd=user")
        .set("Authorization", authToken)
        .end(function(error, res) {
          if (res.status === 200) {
			  console.log(res.text);
            resolve(JSON.parse(res.text));
          } else {
            self.authorizationToken = null; // logged out
            resolve(null);
          }
        });
    });
  }

};

module.exports = jumprunApiClient;
