var Dispatcher = require("flux").Dispatcher;
var assign = require("object-assign");

var JumprunDispatcher = assign(new Dispatcher(), {

	handleToplistAction: function(action) {
		console.log("dispatch page action: ", action);
		this.dispatch({
			type: "toplist",
			action: action
		});
	},

	handleUserAction: function(action) {
		console.log("user action", action);
		this.dispatch({
			type: "user",
			action: action
		});
	},

	handleJumpLogAction: function(action) {
		console.log("jump log action", action);
		this.dispatch({
			type: "jumplog",
			action: action
		});
	},

	handleCommonAction: function(action) {
		this.dispatch({
			type: "common",
			action: action
		});
	}

});

module.exports = JumprunDispatcher;
