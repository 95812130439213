var jrDispatcher = require("../dispatchers/jumprunDispatcher");
var assign = require("object-assign");
var EventEmitter = require("events").EventEmitter;
var api = require("../api/jumprunApiClient");

var CHANGE_EVENT = "change";

var jumplog = [];
var payments = [];
var authorized = true;
var year = "";

function getJumpLog() {
  if (!jumplog[year]) {
    console.log("loading log");
    api.log(year).then(function(data) {
      console.log("log loaded");
      year = data.year;
      jumplog[year] = data;
      JumpLogStore.emitChange();
    }).catch(function(error, status, message) {
      console.log(status);
      console.log(message);
      if (error.status === 401) {
        authorized = false;
        jumplog[year] = {};
        JumpLogStore.emitChange();
      }
    });
  }

  return jumplog[year];
}

function getPayments() {
  if (!payments[year]) {
    api.payments(year).then(function(data) {
      year = data.year;
      payments[year] = data;
      JumpLogStore.emitChange();
    }).catch(function(error, status, message) {
      console.log(message);
      if (error.status === 401) {
        authorized = false;
        payments[year] = {};
        JumpLogStore.emitChange();
      }
    });
  }

  return payments[year];
}

var JumpLogStore = assign({}, EventEmitter.prototype, {

	emitChange: function() {
		this.emit(CHANGE_EVENT);
	},

	addChangeListener: function(callback) {
		this.on(CHANGE_EVENT, callback);
	},

	removeChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENT, callback);
	},

  getJumpLogState: function() {
    console.log("JumpLogStore: getting log state");
    return {
      jumplog: getJumpLog(),
      isLoading: !jumplog[year],
      authorized: authorized,
      year: year
    };
  },

  getPaymentsState: function() {
    return {
      payments: getPayments(),
      isLoading: !payments[year],
      authorized: authorized,
      year: year
    };
  },

  dispatcherToken: jrDispatcher.register(function(payload) {
    console.log("dispatcher callback");

		if (payload.type === "jumplog") {
			switch (payload.action.type) {
				case "reloadLog":
				  console.log("reload log");
          jumplog = [];
					break;
        case "reloadPayments":
				  console.log("reload payments");
          payments = [];
					break;
			}

			JumpLogStore.emitChange();
      return true;

		} else if (payload.type === "user") {
      console.log("user action");
      switch (payload.action.type) {
        case "loggedIn":
        console.log("jumpLogStore: logged in");
          authorized = true;
          payments = [];
          jumplog = [];
          JumpLogStore.emitChange();
          break;
        case "loggedOut":
          authorized = false;
          payments = [];
          jumplog = [];
          JumpLogStore.emitChange();
          break;
      }
    } else if (payload.type === "common" && payload.action.type === "yearSelect") {
      year = payload.action.data;
      JumpLogStore.emitChange();
    }

		return false;
	})

});

module.exports = JumpLogStore;
