var React = require("react");
var ReactDOM = require("react-dom");
var App = require("./components/app");
var api = require("./api/jumprunApiClient");
var routes = require("./router/routes");
var Router = require("react-router").Router;
var browserHistory = require("react-router").browserHistory;
var actionCreator = require("./actions/userActionCreator");

//api.toplist().then(function(toplist) {
//  console.log("toplist: ", toplist);
  /*React.render(
    <App toplist={toplist}/>,
    document.getElementById("app")
  );*/
//api.currentUser().then(function(user) {
  /*Router.run(routes, Router.HistoryLocation, function(Handler) {
    React.render(<Handler />, document.getElementById("app"));
  });*/
  ReactDOM.render(
	 <Router history={browserHistory}>
		 {routes}
	 </Router>,
	 document.getElementById("app")
  );

  actionCreator.loadUser();
//});

//}).catch(function(ex) {
//    console.log("Failed to load toplist", ex);
//});
