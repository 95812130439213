var jrDispatcher = require("../dispatchers/jumprunDispatcher");
var assign = require("object-assign");
var EventEmitter = require("events").EventEmitter;
var api = require("../api/jumprunApiClient");

var CHANGE_EVENT = "change";

var user = {};

var authorized = api.isLoggedIn();
var loginCompleted = false;
var loginError = false;
var logoutCompleted = false;
var registrationError = "NONE";
var registrationComplete = false;
var passwordChangeSuccess = false;
var passwordChangeError = false;

var UserStore = assign({}, EventEmitter.prototype, {

	emitChange: function() {
		this.emit(CHANGE_EVENT);
	},

	addChangeListener: function(callback) {
		this.on(CHANGE_EVENT, callback);
	},

	removeChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENT, callback);
	},

  getUserState: function() {
    return {
      loggedIn: authorized,
			loginCompleted: loginCompleted,
			loginError: loginError,
			registrationComplete: registrationComplete,
			registrationError: registrationError,
			passwordChangeSuccess: passwordChangeSuccess,
			passwordChangeError: passwordChangeError,
			logoutComplete: logoutCompleted,
			user: user
    };
  },

  dispatcherToken: jrDispatcher.register(function(payload) {
		if (payload.type === "user") {
			loginError = false;
			registrationError = "NONE";
			registrationComplete = false;

      console.log("user action");
      switch (payload.action.type) {
        case "loggedIn":
          authorized = true;
					logoutCompleted = false;
          UserStore.emitChange();
          break;
        case "loggedOut":
          authorized = false;
					loginCompleted = false;
					user = null;
          UserStore.emitChange();
          break;
				case "logoutComplete":
					logoutCompleted = true;
          UserStore.emitChange();
          break;
				case "loginComplete":
					loginCompleted = true;
					UserStore.emitChange();
					break;
				case "userLoaded":
					user = payload.action.data;
					UserStore.emitChange();
					break;
				case "loginFailed":
					loginError = true;
					UserStore.emitChange();
					break;
				case "registrationComplete":
					registrationComplete = true;
					UserStore.emitChange();
					break;
				case "registrationFailed":
					registrationError = payload.action.reason;
					UserStore.emitChange();
					break;
				case "passwordChangeBegin":
					passwordChangeSuccess = false;
					passwordChangeError = false;
					UserStore.emitChange();
					break;
				case "passwordChangeSuccess":
					passwordChangeSuccess = true;
					passwordChangeError = false;
					UserStore.emitChange();
					break;
				case "passwordChangeError":
					passwordChangeSuccess = false;
					passwordChangeError = true;
					UserStore.emitChange();
					break;
      }

      return true;
    }

		return false;
	})

});

module.exports = UserStore;
