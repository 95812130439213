var jrDispatcher = require("../dispatchers/jumprunDispatcher");
var api = require("../api/jumprunApiClient");

var ac = {

  logout: function() {
    api.logout();/*.then(function() {*/
      jrDispatcher.handleUserAction({
        type: "loggedOut"
      });
      jrDispatcher.handleUserAction({
        type: "logoutComplete"
      });
    /*}).catch(function(error) {
      console.log("logout error: ", error);
    });*/
  },

  login: function(email, password) {
    api.login(email, password).then(function(res) {
      jrDispatcher.handleUserAction({
        type: "userLoaded",
        data: res.user
      });
      jrDispatcher.handleUserAction({
        type: "loggedIn"
      });
      jrDispatcher.handleUserAction({
        type: "loginComplete"
      });
    }).catch(function() {
      jrDispatcher.handleUserAction({
        type: "loginFailed"
      });
    });
  },

  register: function(email) {
    api.register(email).then(function(res) {
      jrDispatcher.handleUserAction({
        type: "registrationComplete"
      });
    }).catch(function() {
      jrDispatcher.handleUserAction({
        type: "registrationFailed",
        reason: "NOT_FOUND"
      });
    });
  },

  changePassword: function(oldPassword, newPassword) {
    jrDispatcher.handleUserAction({
      type: "passwordChangeBegin"
    });

    api.changePassword(oldPassword, newPassword).then(function() {
      jrDispatcher.handleUserAction({
        type: "passwordChangeSuccess"
      });
    }).catch(function() {
      jrDispatcher.handleUserAction({
        type: "passwordChangeError"
      });
    });
  },

  loadUser: function() {
    api.currentUser().then(function(user) {
      if (user) {
        jrDispatcher.handleUserAction({
          type: "userLoaded",
          data: user
        });
        jrDispatcher.handleUserAction({
          type: "loggedIn"
        });
        jrDispatcher.handleUserAction({
          type: "loginComplete"
        });
      }
    });
  }

};

module.exports = ac;
