/** @jsx React.DOM */
var React = require("react");
var Panel = require("react-bootstrap").Panel;
var Table = require("react-bootstrap").Table;
var store = require("../stores/toplistStore");
var actionCreator = require("../actions/toplistActionCreator.js");

var toplist = React.createClass({

  getInitialState: function() {
    return store.getToplistState();
  },

  changeHandler: function() {
    this.setState(store.getToplistState());
  },

  componentDidMount: function() {
    store.addChangeListener(this.changeHandler);
  },

  componentWillUnmount: function() {
    store.removeChangeListener(this.changeHandler);
  },

  handleYearChange: function(e) {
    e.preventDefault();
    actionCreator.selectYear(this.refs.year.getDOMNode().value);
  },

  renderToplist: function() {
    var position = 0;
    var lastCount = -1;
    var table = this.state.toplist.toplist.map(function(entry, index) {
      if (entry.numberOfJumps != lastCount) {
        position = index+1;
        lastCount = entry.numberOfJumps;
      }
      return (
        <tr key={index}>
          <td>{position}</td>
          <td>{entry.name}</td>
          <td>{entry.numberOfJumps}</td>
        </tr>
      );
    });

    var header = (
      <div>
        <h3>Top number of jumps {this.state.year}</h3>
		{/*
        <select name="year" onChange={this.handleYearChange} className="jr-year-selector" ref="year" value={this.state.year}>
          <option value="2015">2015</option>
          <option value="2014">2014</option>
          <option value="2013">2013</option>
        </select>*/}
      </div>
    );

    return (
      <Panel header={header}>
        <div className="stats">A total of <strong>{this.state.toplist.totalJumps}</strong> jumps, performed by <strong>{this.state.toplist.numberOfJumpers}</strong> skydivers.</div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Jumps</th>
            </tr>
          </thead>
          <tbody>
            {table}
          </tbody>
        </Table>
      </Panel>
    );
  },

  renderLoader: function() {
    return (
      <p>Loading...</p>
    );
  },

  render: function() {
    if (this.state.isLoading) {
      return this.renderLoader();
    }

    return this.renderToplist();
  }

});

module.exports = toplist;
