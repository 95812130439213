var jrDispatcher = require("../dispatchers/jumprunDispatcher");
var assign = require("object-assign");
var EventEmitter = require("events").EventEmitter;
var api = require("../api/jumprunApiClient");

var CHANGE_EVENT = "change";

var toplist = [];
var year = "";

function getToplist() {
	if (!toplist[year]) {
    api.toplist(year).then(function(data) {
			year = data.year;
      toplist[year] = data;
      ToplistStore.emitChange();
    }).catch(function(error, status, message) {
      console.log(message);
      if (error.status === 401) {
        toplist = null;
				year = "";
        ToplistStore.emitChange();
      }
    });
  }

  return toplist[year];
}

var ToplistStore = assign({}, EventEmitter.prototype, {

	emitChange: function() {
		this.emit(CHANGE_EVENT);
	},

	addChangeListener: function(callback) {
		this.on(CHANGE_EVENT, callback);
	},

	removeChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENT, callback);
	},

  getToplistState: function() {
    console.log("getting toplist");
    return {
      toplist: getToplist(),
      isLoading: !toplist[year],
			year: year
    };
  },

	dispatcherToken: jrDispatcher.register(function(payload) {
		if (payload.type === "common") {
      switch (payload.action.type) {
        case "yearSelect":
					year = payload.action.data;
					break;
			}

			ToplistStore.emitChange();
		}
	})
});

module.exports = ToplistStore;
