/** @jsx React.DOM */
var React = require("react");
var Menu = require("./menu");
var Toplist = require("./toplist");
var Panel = require("react-bootstrap").Panel;
var Table = require("react-bootstrap").Table;
var RouteHandler = require("react-router").RouteHandler;

var app = React.createClass({

  render: function() {
    return (
      <div>
        <header>
          <Menu />
        </header>
		{this.props.children}
      </div>
    );
  }
});

module.exports = app;
