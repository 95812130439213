/** @jsx React.DOM */
var React = require("react");
var store = require("../stores/jumpLogStore");
var Panel = require("react-bootstrap").Panel;
var Table = require("react-bootstrap").Table;
var Login = require("./Login");
var actionCreator = require("../actions/toplistActionCreator.js");

var payments = React.createClass({

  contextTypes: {
    router: React.PropTypes.object.isRequired
  },

  getInitialState: function() {
    return store.getPaymentsState();
  },

  changeHandler: function() {
    this.setState(store.getPaymentsState());
  },

  checkLoggedIn: function(s) {
    if (s.authorized === false) {
      this.context.router.replace(window.location.pathname + "/login");
    }
  },

  componentDidMount: function() {
    this.checkLoggedIn(this.state);
    store.addChangeListener(this.changeHandler);
  },

  componentWillUnmount: function() {
    store.removeChangeListener(this.changeHandler);
  },

  componentWillUpdate: function(newProps, newState) {
		this.checkLoggedIn(newState);
	},

  handleYearChange: function(e) {
    e.preventDefault();
    actionCreator.selectYear(this.refs.year.getDOMNode().value);
  },

  renderPayments: function() {

    var table = this.state.payments.items.map(function(entry, index) {
      return (
        <tr key={index}>
          <td>{entry.date}</td>
          <td>{entry.type}</td>
          <td>{entry.description}</td>
          <td>{entry.amount}</td>
          <td>{entry.comment}</td>
        </tr>
      );
    });

    var header = (
      <div>
        <h3>Payments - {this.state.payments.year}</h3>
        {/*<select name="year" onChange={this.handleYearChange} className="jr-year-selector" ref="year" value={this.state.year}>
          <option value="2015">2015</option>
          <option value="2014">2014</option>
          <option value="2013">2013</option>
        </select>*/}
      </div>
    );

    return (
      <Panel header={header}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {table}
          </tbody>
        </Table>
      </Panel>
    );
  },

  renderLoader: function() {
    return (
      <p>Loading...</p>
    );
  },

  render: function() {
    if (this.state.isLoading || this.state.authorized === false) {
      return this.renderLoader();
    } else {
      return this.renderPayments();
    }
  }

});

module.exports = payments;
