/** @jsx React.DOM */
var React = require("react");
var Navbar = require("react-bootstrap").Navbar;
var Nav = require("react-bootstrap").Nav;
var NavItem = require("react-bootstrap").NavItem;
var NavItemLink = require("react-router-bootstrap").NavItemLink;
var LinkContainer = require("react-router-bootstrap").LinkContainer;
var store = require("../stores/userStore");
var actionCreator = require("../actions/userActionCreator");
var Link = require("react-router").Link;

var Menu = React.createClass({

  getInitialState: function() {
    return store.getUserState();
  },

  changeHandler: function() {
    this.setState(store.getUserState());
  },

  componentDidMount: function() {
    store.addChangeListener(this.changeHandler);
  },

  componentWillUnmount: function() {
    store.removeChangeListener(this.changeHandler);
  },

  onLogout: function(e) {
    e.preventDefault();
    actionCreator.logout();
  },

  render: function() {
    var logout = "";
    if (!this.state.loggedIn) {
      logout = <LinkContainer to={{pathname: '/login'}}><NavItem>Log in</NavItem></LinkContainer>
    }

    var userData = "";
    if (this.state.loggedIn) {
        userData = (
          <div className="jr-user-data">
            {this.state.user.name} | Balance: {this.state.user.balance} | Total # jumps: {this.state.user.totalJumps}
            <div className="jr-user-links">
              <Link to="change-password">Change password</Link>&nbsp;|&nbsp;
              <a href="#" onClick={this.onLogout}>Log out</a>
            </div>
          </div>
        );
    }

    return (
      <div>
        {userData}
        <Navbar>
		<Navbar.Header>
			<Navbar.Toggle />
		</Navbar.Header>
		<Navbar.Collapse>
          <Nav left>
            <LinkContainer to={{pathname: '/toplist'}}><NavItem>Toplist</NavItem></LinkContainer>
            <LinkContainer to={{pathname: '/log'}}><NavItem>Log</NavItem></LinkContainer>
            <LinkContainer to={{pathname: '/payments'}}><NavItem>Payments</NavItem></LinkContainer>
            {logout}
          </Nav>
	  </Navbar.Collapse>
	  </Navbar>
      </div>
    );
  }
});

module.exports = Menu;
